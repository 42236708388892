import axios from "axios";
import { types } from "../types/types";
import { handeleError, handeleSuccess } from "./Toast/toast";
const consultationsTypes = types.consultationsTypes;

const API_URL = process.env.REACT_APP_API_URL;

export const getActiveConsultations = () => {
  return async (dispatch) => {
    dispatch({
      type: consultationsTypes.GET_ACTIVE_CONSULTATIONS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/consultant/consultation-request/my-requests`,
      });

      dispatch({
        type: consultationsTypes.GET_ACTIVE_CONSULTATIONS_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      // handeleError(error, toast, toast);
      dispatch({
        type: consultationsTypes.GET_ACTIVE_CONSULTATIONS_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const getSingleConsultations = (id, toast, history) => {
  return async (dispatch) => {
    dispatch({
      type: consultationsTypes.GET_SINGLE_CONSULTATION_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/consultant/consultation-request/single/${id}`,
      });
      dispatch({
        type: consultationsTypes.GET_SINGLE_CONSULTATION_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      history.goBack();
      dispatch({
        type: consultationsTypes.GET_SINGLE_CONSULTATION_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

//// Question
export const getQuestionList = (id, pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: consultationsTypes.GET_QUESTIONS_LIST_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/consultant/consultant-actions/${id}/question?page=${pageNumber}`,
      });
      dispatch({
        type: consultationsTypes.GET_QUESTIONS_LIST_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      // handeleError(error, toast);
      dispatch({
        type: consultationsTypes.GET_QUESTIONS_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const getSingleQuestion = (id, questionId) => {
  return async (dispatch) => {
    dispatch({
      type: consultationsTypes.GET_SINGLE_QUESTION_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/consultant/consultant-actions/${id}/question/show/${questionId}`,
      });
      dispatch({
        type: consultationsTypes.GET_SINGLE_QUESTION_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      // handeleError(error, toast);
      dispatch({
        type: consultationsTypes.GET_SINGLE_QUESTION_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const requestQuestion = (
  id,
  data,
  history,
  toast,
  getSingleConsultations,
  getQuestionList
) => {
  return async (dispatch) => {
    dispatch({
      type: consultationsTypes.REQUEST_QUESTION_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/consultant-actions/${id}/request-question`,
        data: data,
      });
      history.goBack();
      handeleSuccess("Your Question has been sent successfully", toast);
      getSingleConsultations(id);
      getQuestionList(id);
      dispatch({
        type: consultationsTypes.REQUEST_QUESTION_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      // handeleError(error, toast);
      dispatch({
        type: consultationsTypes.REQUEST_QUESTION_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const addMedicalReport = (
  data,
  history,
  toast,
  getSingleConsultations,
  id
) => {
  let formData = new FormData();
  formData.append("consultationId", data.consultationId);
  formData.append("title", data.title);
  formData.append("note", data.note);
  if (data.files) {
    data.files.map((file) => formData.append("file", file, file.name));
  }

  return async (dispatch) => {
    dispatch({
      type: consultationsTypes.ADD_MEDICAL_REPORT_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/medical-reports`,
        data: formData,
      });
      if (res) {
        handeleSuccess(
          "Your Medical Report has been Added successfully",
          toast
        );
        getSingleConsultations(id);
        history.push(`/consultation/${id}/medical-report`);
        dispatch({
          type: consultationsTypes.ADD_MEDICAL_REPORT_SUCSES,
          payload: res.data,
        });
        localStorage.removeItem("reportHtml");
      }
    } catch (error) {
      handeleError(error, toast);
      // getSingleConsultations(id);
      // history.push(`/consultation/${id}/medical-report`);
      dispatch({
        type: consultationsTypes.ADD_MEDICAL_REPORT_ERROR,
        payload: error.response,
      });
    }
  };
};

export const requestScan = (
  data,
  toast,
  getSingleConsultations,
  id,
  onClose,
  history
) => {
  return async (dispatch) => {
    dispatch({
      type: consultationsTypes.REQUEST_SCAN_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/consultant-actions/request-scan`,
        data: data,
      });
      handeleSuccess("The Request has been sent successfully", toast);
      getSingleConsultations(id);
      if (history) history.goBack();
      if (onClose) onClose();
      dispatch({
        type: consultationsTypes.REQUEST_SCAN_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      if (history) history.goBack();
      dispatch({
        type: consultationsTypes.REQUEST_SCAN_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};
export const acceptNewConsultation = (
  consultantionId,
  toast,
  { getUserConsultations, getSingleConsultations }
) => {
  // console.log(
  //   "🚀 ~ file: consultationActions.js:237 ~ acceptNewConsultation ~ getUserConsultations",
  //   consultantionId,
  //   getSingleConsultations
  // );
  return async (dispatch) => {
    dispatch({
      type: consultationsTypes.ACCEPT_NEW_CONSULTATION_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/consultation-request/${consultantionId}/accept`,
      });
      handeleSuccess("The Request has been accepted successfully", toast);
      if (getUserConsultations) {
        getUserConsultations();
      }
      if (getSingleConsultations) {
        getSingleConsultations(consultantionId);
      }
      dispatch({
        type: consultationsTypes.ACCEPT_NEW_CONSULTATION_SUCSES,
        payload: res.data?.data,
      });
    } catch (error) {
      // console.log(
      //   "🚀 ~ file: consultationActions.js:258 ~ return ~ error",
      //   error
      // );
      dispatch({
        type: consultationsTypes.ACCEPT_NEW_CONSULTATION_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};
export const rejectNewConsultation = (
  consultantionId,
  toast,
  { getUserConsultations, history }
) => {
  return async (dispatch) => {
    dispatch({
      type: consultationsTypes.REJECT_NEW_CONSULTATION_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/consultant/consultation-request/${consultantionId}/reject`,
      });
      handeleSuccess("The Request has been rejected successfully", toast);
      if (getUserConsultations) {
        getUserConsultations();
      }
      if (history) history.goBack();
      dispatch({
        type: consultationsTypes.REJECT_NEW_CONSULTATION_SUCSES,
        payload: res.data?.data,
      });
    } catch (error) {
      dispatch({
        type: consultationsTypes.REJECT_NEW_CONSULTATION_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};
