import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Text, Avatar, useToast, Box, useColorMode } from "@chakra-ui/react";
import { connect } from "react-redux";
import { checkColorMode } from "../../global/changeColor";

import {
  getConsultantProfile,
  postConsultantProfilePicture,
} from "../../../store/actions";
const UploadProfilePic = ({
  postConsultantProfilePicture,
  profile,
  getConsultantProfile,
  ConsultantPicture,
}) => {
  const [ConsultantProfile, setConsultantProfile] = useState({});
  const toast = useToast();
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (profile) {
      setConsultantProfile(profile.data);
    }
  }, [profile]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles[0].size > 209715200) {
        return toast({
          title: "The file size must be less than  or equal 200mb",
          status: "error",
          duration: 7000,
          isClosable: true,
          position: "top-right",
        });
      }
      postConsultantProfilePicture(acceptedFiles, toast).then((res) => {
        getConsultantProfile();
      });
    },
  });
  return (
    <Box
      {...getRootProps({
        className: `${
          ConsultantPicture && ConsultantPicture.loading ? "disabled-pic" : ""
        }`,
      })}
    >
      <input {...getInputProps()} />
      <Box className="avatar">
        <Avatar
          size="xl"
          name={ConsultantProfile ? ConsultantProfile.fullName : ""}
          src={ConsultantProfile ? ConsultantProfile.picture : ""}
        />
        <Text className="camera rounded-circle" bg={checkColorMode(colorMode)}>
          <span>
            <i className="fas fa-camera"></i>
          </span>
        </Text>
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    ConsultantPicture: state.updateConsultantPicture,
  };
};
export default connect(mapStateToProps, {
  postConsultantProfilePicture,
  getConsultantProfile,
})(UploadProfilePic);
